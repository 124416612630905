
.basic-landing-header{
    padding-left: 9vw;
    font-size: clamp(12px, 1.17vw, 18px);
    margin-bottom: 3em;
    position: relative;

    @media (max-width: 991px) {
        padding-left: 4vw;
        font-size: clamp(9px, 1.17vw, 18px);
    }
    @media (max-width: 767px){
        padding: 0;
    }
}
.basic-landing-header-text{
    width: 43.4%;
    padding-right: 1.6em;
    padding-top: 8vw;

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        margin-bottom: 5em;
        margin-top: 6em;
        padding: 0 20px;
    }
    h1{
        font-weight: 400;
        margin-bottom: 0.6em;
        font-size: 2.8em;
        line-height: 1.22;

        @media (max-width: 767px){
            font-size: 30px;
        }
    }
    > p{
        margin-bottom: 2.3em;
        font-size: 1.185em;
        line-height: 1.35;

        @media (max-width: 991px){
            font-size: 1.5em;
        }
        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.basic-landing-header-img{
    width: 56.55%;
    padding: 2.5% 3.5%;

    @media (max-width: 767px){
        width: 100%;
    }
    > img{
        width: 100%;
        object-fit: cover;
        height: auto;

        @media (max-width: 767px){
            height: auto;
        }
    }
}
.basic-landing-head-bg{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.basic-landing-2-container{
    max-width: 1147px;
    padding: 20px;
    margin: 3em auto;

    @media (max-width: 767px) {

    }
}
.basic-landing-2-left{
    width: 51%;
    padding: 8.8% 0 13% 11.5%;
    max-width: 556px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
    }
}
.basic-landing-2-left-img{
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}
.basic-landing-2-left-bg{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.basic-landing-2-right{
    width: 45%;
    margin-left: auto;
    max-width: 435px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
    }
    > h2{
        font-size: 35px;
        font-weight: 400;
        margin-bottom: .9em;

        @media (max-width: 991px) {
            font-size: 26px;
        }
    }
    > p{
        font-size: 18px;
        letter-spacing: 0.03em;
        line-height: 1.55;
        margin-bottom: 2em;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }
}
.basic-landing-3{
    background-color: #F6F6F7;
}
.basic-landing-3-container{
    max-width: 1147px;
    padding: 40px 20px;
    margin: 7em auto 7em;

    @media (max-width: 767px) {

    }
}
.basic-landing-3-right{
    width: 51%;
    padding: 8.8% 11.5% 13% 0;
    max-width: 556px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
    }
}
.basic-landing-3-right-img{
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
}
.basic-landing-3-right-bg{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(2);
}
.basic-landing-3-left{
    width: 45%;
    margin-right: auto;
    max-width: 435px;

    @media (max-width: 767px) {
        width: 100%;
        max-width: inherit;
    }
    > h2{
        font-size: 35px;
        font-weight: 400;
        margin-bottom: .9em;

        @media (max-width: 991px) {
            font-size: 26px;
        }
    }
    > p{
        font-size: 18px;
        letter-spacing: 0.03em;
        line-height: 1.55;
        margin-bottom: 2em;

        @media (max-width: 991px) {
            font-size: 16px;
        }
    }
}

