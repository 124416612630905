
.basic-header{
    padding-left: 9vw;
    font-size: clamp(12px, 1.17vw, 18px);
    margin-bottom: 10em;

    @media (max-width: 991px) {
        padding-left: 4vw;
        font-size: clamp(9px, 1.17vw, 18px);
    }
    @media (max-width: 767px){
        padding: 0;
        margin-bottom: 7em;
    }
}
.basic-header-text{
    width: 49.6%;
    padding-right: 1.6em;
    padding-top: 1em;

    @media (max-width: 767px){
        width: 100%;
        text-align: center;
        margin-bottom: 5em;
        margin-top: 6em;
        padding: 0 20px;
    }
    h1{
        font-weight: 400;
        margin-bottom: .87em;
        font-size: 2.8em;
        line-height: 1.22;

        @media (max-width: 767px){
            font-size: 28px;
        }
    }
    > p{
        margin-bottom: 2.1em;
        font-size: 1.185em;
        line-height: 1.35;
        max-width: 23em;

        @media (max-width: 991px){
            font-size: 1.5em;
        }
        @media (max-width: 767px){
            font-size: 18px;
        }
    }
}
.basic-head-title-img{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: auto;
    width: 12em;
}
.basic-header-img{
    width: 50.4%;

    @media (max-width: 767px){
        width: 100%;
    }
    > img{
        width: 100%;
        height: 37.55vw;
        object-fit: cover;

        @media (max-width: 767px){
            height: auto;
        }
    }
}
.basic-section-2{
    padding-right: 9vw;
    margin-top: 10em;
    margin-bottom: 10em;

    @media (max-width: 991px) {
        padding-right: 4vw;
        margin-top: 5em;
        margin-bottom: 5em;
    }
    @media (max-width: 767px){
        padding-right: 0;
        margin-top: 3em;
        margin-bottom: 3em;
    }
}
.basic-section-2-container{
    max-width: 1500px;
}
.basic-section-2-left{
    width: 55.5%;
    padding-right: 2em;
    padding-left: 2em;
    max-width: 616px;

    @media (max-width: 767px){
        width: 100%;
        padding: 0 6vw;
    }
}
.section-2-basic-oval{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    max-height: 100%;
    width: 44%;
    height: auto;

    @media (max-width: 767px){
        width: 100%;
        top: 38%;
    }
}
.basic-section-2-block{
    padding-top: 1.6em;
    max-width: 459px;
    margin-left: auto;
}
.basic-section-2-item{
    background-color: #ffffff;
    margin-bottom: 25px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 16%);
    padding: 28px 25px;
    min-height: 235px;
    width: 47%;
    max-width: 212px;

    @media (max-width: 767px){
        padding: 20px 15px;
        min-height: 158px
    }
    > b{
        font-size: 2.3em;
        margin-bottom: .5em;
        display: block;

        @media (max-width: 767px){
            font-size: 1.8em;
        }
    }
    > p{
        font-size: 1.12em;

        @media (max-width: 767px){
            font-size: .94em;
        }
    }
}
.basic-section-2-item-yellow{
    background-color: #F0AE00;
    color: #ffffff;
}
.basic-section-2-right{
    width: 44.5%;
    max-width: 650px;
    margin-left: auto;

    @media (max-width: 767px){
        width: 100%;
        padding: 0 20px;
    }

    > h2{
        font-weight: 400;
        margin-bottom: 1.55em;
        font-size: 2.2em;
        margin-top: 1.35em;
        text-align: right;

        @media (max-width: 991px){
            font-size: 1.6em;
        }
    }
    > p{
        font-size: 1.185em;

        @media (max-width: 991px){
            font-size: 1em;
        }
    }
}
.basic-section-3{
    margin-top: 10em;
    margin-bottom: 10em;

    @media (max-width: 991px) {
        margin-top: 5em;
        margin-bottom: 5em;
    }
    @media (max-width: 767px){
        margin-top: 3em;
        margin-bottom: 3em;
    }
}
.basic-section-4{
    margin-top: 10em;
    margin-bottom: 10em;

    @media (max-width: 991px) {
        margin-top: 5em;
        margin-bottom: 5em;
    }
    @media (max-width: 767px) {
        margin-top: 3em;
        margin-bottom: 3em;
    }

    > h2{
        margin-bottom: 2.1em;
        font-size: 2.2em;
        font-weight: 400;

        @media (max-width: 991px){
            font-size: 1.6em;
        }
    }
}
.basic-section-4-container{
    margin: 0 auto;
    max-width: 1170px;
}
.basic-section-4-item{
    width: 33.33%;
    padding: 0 20px;
    margin-bottom: 30px;

    @media (max-width: 767px) {
        width: 100%;
        text-align: center;
    }
    > img{
        width: 213px;
        height: 213px;
        border-radius: 50%;
        margin-bottom: 20px;
        object-fit: cover;

        @media (max-width: 991px){
            width: 170px;
            height: 170px;
        }
        @media (max-width: 767px){
            width: 232px;
            height: 232px;
        }
    }
    > h3{
        font-size: 20px;
        margin-bottom: .5em;
    }
    > h4{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 1.15em;
    }
    > p{
        font-size: 17px;
        line-height: 1.35;
        margin-bottom: 0;

        @media (max-width: 991px){
            font-size: 14px;
        }
        @media (max-width: 767px){
            font-size: 16px;
        }
    }
}
.basic-section-5{
    margin-top: 10em;
    margin-bottom: 7em;

    @media (max-width: 991px) {
        margin-top: 5em;
        margin-bottom: 5em;
    }
    @media (max-width: 767px) {
        margin-top: 3em;
        margin-bottom: 3em;
    }
    > h2{
        margin-bottom: 2.1em;
        font-size: 2.2em;
        font-weight: 400;

        @media (max-width: 991px){
            font-size: 1.6em;
        }
    }
}
.basic-section-5-container{
    max-width: 1120px;
    margin: 0 auto;
    column-gap: 6em;
    column-count: 2;
    padding: 0 30px;

    @media (max-width: 991px){
        column-gap: 3em;
    }
    @media (max-width: 767px){
        column-count: 1;
        column-gap: 0;
        padding: 0 20px;
    }
}
.basic-section-5-item{
    border-bottom: solid 5px #F0AE00;
    padding-bottom: 16px;
    margin-bottom: 33px;
    break-inside: avoid-column;

    @media (max-width: 767px){
        margin-bottom: 50px;
    }
    > p{
        font-size: 1.12em;
        line-height: 1.6;
        margin-bottom: 1.45em;

        @media (min-width: 768px) and (max-width: 991px){
            font-size: 1em;
        }
        @media (max-width: 767px){
            font-size: 1.03em;
        }
    }
}
.basic-section-5-item-author{
    padding: 8px 0;
    display: flex;
    align-items: center;

    > img{
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 13px;
        border-radius: 50%;
    }
    > span{
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
}
.quot-student{
    position: absolute;
    left: 0;
    top: -8%;
    z-index: -1;
    width: 30%;

    @media (max-width: 767px){
        width: 100%;
        top: 0;
    }
}




//video course
:root {
    --course-background: #ffffff;
    --course-background-hover: #fafafa;
    --course-color: #1D1D1D;
    --course-color-hover: #1D1D1D;
    --course-color-hr: #e4e8f2;
    --course-color-hr-hover: #e4e8f2;
}
.home-course-section{
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    z-index: 1;

    > h2{
        margin-bottom: 1em;
        padding-left: 1.2em;
        font-weight: 400;
        font-size: 2.2em;
        padding-top: 32px;

        @media (max-width: 991px){
            font-size: 1.6em;
        }
        @media (max-width: 767px){
            margin-bottom: .5em;
            padding-top: 0;
        }
    }
}
.home-course-section-container{
    padding-bottom: 30px;
    padding-top: 30px;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;
    justify-content: center;
}
.home-course-item-block{
    width: 370px;
    padding: 0 15px;
    transition: color .3s;

    &:hover{
        color: #1D1D1D;
        text-decoration: none;

        .home-course-author, .home-course-info-block, .home-course-price{
            background-color: var(--course-background-hover);
            color: var(--course-color-hover);
        }
        .home-course-item{
            box-shadow: 0 2px 6px #0000000f;
        }
        hr{
            border-color: var(--course-color-hr-hover);
        }
    }
    hr{
        margin: 0;
        border-color: var(--course-color-hr);
        transition: all .3s;
    }
    @media (max-width: 390px){
        width: 345px;
    }
}
.home-course-item {
    width: 100%;
    color: #1D1D1D;
    box-shadow: 0 2px 6px #00000029;
    display: block;
    border-radius: 16px;
    margin: 0 0 23px;
    position: relative;
    transition: all .3s;
}
.home-course-item-img{
    border-radius: 16px 16px 0 0;
    height: 166px;
    width: 100%;
    object-fit: cover;
}
.home-course-author{
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 8px 20px;
    display: flex;
    align-items: center;
    transition: background-color .3s;

    img{
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 50%;
    }
    span{
        font-size: 14px;
        font-weight: 500;
        font-family: NotoSansArmenianBold,Poppins,Open Sans,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-grow: 1;
    }
}
.home-course-info-block{
    background-color: var(--course-background);
    color: var(--course-color);
    padding: 10px 20px;
    height: 173px;
    transition: background-color .3s;

    h2{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-family: NotoSansArmenianBold,Poppins,Open Sans,-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }
    p{
        font-size: 14px;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0;
    }
}
.home-course-price{
    background-color: var(--course-background);
    color: var(--course-color);
    transition: background-color .3s;
    padding: 11px 20px;
    border-radius: 0 0 16px 16px;
    line-height: 1.2;
    height: 48px;

    @media (max-width: 400px){
        padding: 10px 15px;
    }
    p{
        font-size: 14px;
        padding-right: 10px;

        @media (max-width: 358px){
            font-size: 12px;
        }
    }
    span{
        font-size: 20px;
        font-weight: 700;

        @media (max-width: 400px){
            font-size: 18px;
        }
        @media (max-width: 358px){
            font-size: 16px;
        }
        del{
            font-size: 16px;
            opacity: .8;
            margin-right: 12px;
            margin-bottom: 3px;
            display: inline-block;

            @media (max-width: 400px){
                font-size: 14px;
            }
            @media (max-width: 358px){
                margin-right: 7px;
                margin-bottom: 0;
            }
        }
    }
}
//end video course

