

.basic-info-header{
    min-height: 35.7vw;
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        flex-direction: column;
        min-height: auto;
    }
}
.basic-info-header-text{
    padding: 3vw 20vw 3vw 32.5vw;
    position: relative;
    z-index: 1;
    font-size: clamp(10px, 1.172vw, 18px);

    @media (max-width: 767px){
        position: static;
        font-size: 16px;
        padding: 8vw 20px 6vw 20px;
    }
    h1{
        font-weight: 400;
        line-height: 1.4;
        font-size: 2.5em;
        margin-bottom: .55em;

        @media (max-width: 767px){
            line-height: 1.2;
            margin-bottom: 0;
            position: absolute;
            right: 16px;
            top: 13vw;
            width: 54%;
            font-size: 5.5vw;
        }
    }
    > p{
        letter-spacing: .03em;
        line-height: 1.55;
        font-size: 1.125em;
        margin-bottom: 1.8em;

        @media (max-width: 767px){
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 1.2em;
            letter-spacing: 0;
        }
    }
    > .btn{
        font-size: 1em;
    }
}
.basic-info-header-img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;

    @media (max-width: 767px){
        height: 45vw;
        object-position: left;
        position: static;
    }
}
.basic-info-container{
    max-width: 720px;
    margin: 0 auto;
    padding: 50px 20px 50px;
    font-size: 18px;

    @media (min-width: 1500px) {
        max-width: 1000px;
    }
    iframe{
        margin-bottom: 20px;
        margin-top: 20px;
        max-width: 100%;
        max-height: 380px;

        @media (min-width: 1500px) {
            max-height: 540px;
        }
        @media (max-width: 720px) {
            height: 51vw;
        }
    }
}

